import React from 'react';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';

const Test = () => {
  return (
    <div>
      <Datetime inputProps={{ style: { border: '1px solid' }, className: 'your-custom-class' }} />
    </div>
  )
}

export default Test;

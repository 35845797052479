import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function copyScriptToClipboard() {
    const scriptToCopy = `
    const reels = [];

    function getReels() {
      const allReelRows = document.querySelectorAll("._ac7v._al5r");
    
      allReelRows.forEach((row) => {
        const items = row.querySelectorAll("._abq3._al5p");
    
        items.forEach((item) => {
          const reelElement = item.querySelector("._a6hd");
          const link = "https://www.instagram.com/" + reelElement.getAttribute("href");
    
          // Check if the link is already in the reels array
          const existingReel = reels.find((reel) => reel.link === link);
    
          if (!existingReel) {
            const ulElement = reelElement?.querySelector("._abpo");
            const liElements = ulElement?.querySelectorAll("li");
            let likes = liElements?.[0]?.textContent;
            let comments = liElements?.[1]?.textContent;
            const element = reelElement?.querySelector("._aajy");
            const spanWithClass = element?.querySelector(".html-span");
            // Get the views.
            const views = spanWithClass?.textContent;
    
            // Add a null check for link to avoid adding reels with undefined links
            if (link) {
              reels.push({
                link,
                likes,
                comments,
                views,
              });
            }
          }
        });
      });
    
      console.log(reels);
    }
    
    getReels();
    `;
    const textarea = document.createElement("textarea");
    textarea.value = scriptToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("Copied successfully!", {
      position: "bottom-right",
    });
  }

  return (
    <header className="text-gray-600 body-font sticky">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <Link
          to="/"
          className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
            viewBox="0 0 24 24"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
          </svg>
          <span className="ml-3 text-xl">Webophilia</span>
        </Link>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <Link className="mr-5 hover:text-gray-900" to={"/instagram"}>
            Instagram
          </Link>
          <div className="relative group">
            {/* <button
              onClick={toggleDropdown}
              className="mr-5 hover:text-gray-900 focus:outline-none"
            >
              Research Data
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 inline-block ml-1 transform group-hover:rotate-180 transition-transform duration-300"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 0a1 1 0 01.704.296l7 7a1 1 0 11-1.408 1.408L10 2.414 3.704 8.704a1 1 0 01-1.408-1.408l7-7A1 1 0 0110 0zm0 17a1 1 0 01-.704-.296l-7-7a1 1 0 111.408-1.408L10 17.586l6.296-6.296a1 1 0 111.408 1.408l-7 7A1 1 0 0110 17z"
                  clipRule="evenodd"
                />
              </svg>
            </button> */}
            {isOpen && (
              <div className="absolute z-10 mt-2 w-32 bg-white border rounded-lg shadow-lg">
                {/* <Link
                  to={"/insta-add"}
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={toggleDropdown}
                >
                  Reels
                </Link>
                <a
                  href="https://inflact.com/tools/profile-analyzer/"
                  target="_blank"
                  className="block px-4 py-2 hover:bg-gray-100"
                  rel="noopener noreferrer"
                >
                  Insta Post
                </a> */}
                {/* <Link to={"/insta-add"} className="block px-4 py-2 hover:bg-gray-100" onClick={toggleDropdown}>Instagram</Link>
            <Link to={"/insta-add"} className="block px-4 py-2 hover:bg-gray-100" onClick={toggleDropdown}>Instagram</Link> */}
              </div>
            )}
          </div>
          <a className="mr-5 hover:text-gray-900" href="https://main--incredible-mooncake-f92479.netlify.app/">
        Tele Upload
      </a>
        </nav>
        {/* <button
          className="inline-flex items-center bg-pink-200 border-0 py-1 px-3 focus:outline-none hover:bg-pink-300 rounded text-base mt-4 md:mt-0"
          onClick={copyScriptToClipboard}
        >
          Get Reels Script
        </button> */}
      </div>
    </header>
  );
};

export default Header;

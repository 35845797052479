import Header from './components/Header';
import './App.css';
import InstagramAdd from './pages/InstagramAdd';
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div>
       <ToastContainer />
      <Header/>
      {/* <InstagramAdd /> */}
      <div id="detail">
        <Outlet />
      </div>
    </div>
  );
}

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtnxPqRO02nUUyNHwQEBiRZl3lrBjzWXc",
  authDomain: "webophilia-social-media.firebaseapp.com",
  projectId: "webophilia-social-media",
  storageBucket: "webophilia-social-media.appspot.com",
  messagingSenderId: "706599105080",
  appId: "1:706599105080:web:e2bdcf4066f47d206aaccd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();

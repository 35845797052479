import React from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const CardWithThreeDots = ({ title, script, color, href, icon }) => (
    <div
      className={`bg-white w-48 h-64 rounded-lg p-4 flex flex-col justify-between`}
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        border: "1px solid",
        height: 250,
        position: "relative",
        minWidth: 189,
      }}
    >
      <div className="flex p-2 gap-1">
        <div className="">
          <span
            className={`bg-${color[0]} inline-block w-3 h-3 rounded-full`}
          ></span>
        </div>
        <div className="circle">
          <span
            className={`bg-${color[1]} inline-block w-3 h-3 rounded-full`}
          ></span>
        </div>
        <div className="circle">
          <span
            className={`bg-${color[2]} inline-block w-3 h-3 rounded-full`}
          ></span>
        </div>
      </div>
      <div className="card__content">
        {icon}
        {!href && (
          <button
            className={`bg-${color[0]} hover:bg-${color[1]} text-white font-bold py-2 px-4 rounded w-full`}
            style={{ width: 156, position: "absolute", bottom: 10 }}
            onClick={() => {
              navigator.clipboard.writeText(script);
              toast.success(`${title} Script copied successfully!`, {
                position: "top-right",
              });
            }}
          >
            Copy {title} Script
          </button>
        )}
        {href && (
          <Link
            className={`bg-${color[0]} hover:bg-${color[1]} text-white font-bold py-2 px-4 rounded w-full text-center`}
            style={{ width: 156, position: "absolute", bottom: 10 }}
            to={href}
          >
            Go to {title}
          </Link>
        )}
      </div>
    </div>
  );

export default CardWithThreeDots
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SlUserFollow } from "react-icons/sl";
import { FcLike } from "react-icons/fc";
import { BsCameraReels, BsGraphUpArrow, BsFillFilePostFill } from "react-icons/bs";
import {
  followScript,
  likeCommentsScript,
  reelsExtractorScript,
} from "../utils/scripts";
import CardWithThreeDots from "../components/CardWithThreeDots";

const instaGramItems = [
  {
    title: "Follow",
    script: followScript,
    color: ["blue-500", "blue-700", "blue-900"],
    icon: (
      <SlUserFollow
        fontSize={50}
        style={{ position: "absolute", top: 90, width: "83%" }}
      />
    ),
  },
  {
    title: "Like Comments",
    script: likeCommentsScript,
    color: ["green-500", "green-700", "green-900"],
    icon: (
      <FcLike
        fontSize={50}
        style={{ position: "absolute", top: 90, width: "83%" }}
      />
    ),
  },
  {
    title: "Reels Extractor",
    script: reelsExtractorScript,
    color: ["purple-500", "purple-700", "purple-900"],
    icon: (
      <BsCameraReels
        fontSize={50}
        style={{ position: "absolute", top: 90, width: "83%" }}
      />
    ),
  },
  {
    title: "Instagram Posts",
    href: "/post-instagram",
    color: ["red-500", "red-700", "red-900"],
    icon: (
      <BsFillFilePostFill
        fontSize={50}
        style={{ position: "absolute", top: 90, width: "83%" }}
      />
    ),
  },
  {
    title: "Analyze Reels",
    href: "/insta-add",
    color: ["orange-500", "orange-700", "orange-900"],
    icon: (
      <BsGraphUpArrow
        fontSize={50}
        style={{ position: "absolute", top: 90, width: "83%" }}
      />
    ),
  },
];
const Instagram = () => {
  return (
    <div>
      <h1 className="text-3xl font-semibold mb-4 text-center mt-8">
        Welcome to Instagram Tools
      </h1>
      <div className="flex items-center justify-center p-4 space-y-4 gap-10 flex-col flex-wrap md:flex-row">
        {instaGramItems.map((item, index) => (
          <CardWithThreeDots key={item.title} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Instagram;

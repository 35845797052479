import React, { useState, useEffect } from "react";
// import { db } from "../firebase/firebaseConfig"
import {
  collection,
  addDoc,
  getFirestore,
  query,
  getDocs,
  deleteDoc,
  doc,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { INSTAGRAM_ACCOUNTS } from "../constants";
import { changeTimeFormat, convertToNumber, copyToClipboard, replaceUsernames } from "../utils/common";
import { GoPaste } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import * as clipboard from "clipboard-polyfill";

const AnalyzeDataComponent = () => {
  const [inputValue, setInputValue] = useState("");
  const [followersCount, setFollowersCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [caption, setCaption] = useState([]);
  const [activeTab, setActiveTab] = useState("mostLiked");
  const [bookmarkedReels, setBookmarkedReels] = useState([]);
  const [autoSchedule, setAutoSchedule]  = useState(localStorage.getItem("autoSchedule") || false);
  const [postsPerDay, setPostsPerDay]  = useState(localStorage.getItem("postsPerDay") || 2);
  const [selectedChannel, setSelectedChannel] = useState(
    localStorage.getItem("selectedChannel") || ""
  );

  useEffect(() => {
    localStorage.setItem("selectedChannel", selectedChannel);
  }, [selectedChannel]);
  // Function to convert likes, comments, and views to numbers for sorting
  const calculateLikeRatio = (likes, followersCount) => {
    const likesNumber = convertToNumber(likes);
    if (likesNumber && followersCount) {
      return ((likesNumber / followersCount) * 100).toFixed(2) + "%";
    }
    return "N/A";
  };

  // Load bookmarkedReels from Firestore when the component mounts
  useEffect(() => {
    const fetchBookmarkedReels = async () => {
      try {
        // Create a query to get all documents from the Firestore collection
        const q = query(collection(db, "instagram"));

        // Get the documents that match the query
        const querySnapshot = await getDocs(q);

        // Initialize an array to store the retrieved bookmarked reels
        const retrievedBookmarkedReels = [];

        // Loop through the documents and add them to the array
        querySnapshot.forEach((doc) => {
          retrievedBookmarkedReels.push(doc.data());
        });
console.log('Fetched bookmarks', retrievedBookmarkedReels);
        // Set the retrieved bookmarked reels to your state
        setBookmarkedReels(retrievedBookmarkedReels);
      } catch (error) {
        console.error("Error fetching bookmarked reels: ", error);
      }
    };

    // Call the fetchBookmarkedReels function to retrieve the bookmarks when the component mounts
    fetchBookmarkedReels();
  }, []);
  const sortByLikes = (data) => {
    const sortedData = [...data].sort(
      (a, b) => convertToNumber(b.likes) - convertToNumber(a.likes)
    );
    setData(sortedData);
    setActiveTab("mostLiked");
  };

  const sortByComments = () => {
    const sortedData = [...data].sort(
      (a, b) => convertToNumber(b.comments) - convertToNumber(a.comments)
    );
    setData(sortedData);
    setActiveTab("mostCommented");
  };
  const sortByViews = () => {
    const sortedData = [...data].sort(
      (a, b) => convertToNumber(b.views) - convertToNumber(a.views)
    );
    setData(sortedData);
    setActiveTab("mostViewed");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleFollowersCountChange = (e) => {
    const inputValue = e.target.value;
    // Check if inputValue contains 'k' or 'K' and parse accordingly
    let numericValue;
    if (inputValue.toLowerCase().includes("k")) {
      numericValue = parseFloat(inputValue) * 1000;
    } else if (inputValue.toLowerCase().includes("m")) {
      numericValue = parseFloat(inputValue) * 1000000;
    } else {
      numericValue = parseFloat(inputValue);
    }
    // Check if numericValue is a valid number, and if not, set followersCount to 0
    if (!isNaN(numericValue)) {
      setFollowersCount(numericValue);
    } else {
      setFollowersCount(0);
    }
  };

  const handleAnalyzeClick = () => {
    try {
      const parsedData = JSON.parse(inputValue);

      // Calculate the like ratio and add it to each reel object
      const dataWithLikeRatio = parsedData.map((reel) => ({
        ...reel,
        likeRatio: calculateLikeRatio(reel.likes, followersCount),
        channel: selectedChannel,
        posted: false,
      }));

      sortByLikes(dataWithLikeRatio);
    } catch (error) {
      alert("Invalid JSON input. Please enter valid JSON data.");
    }
  };

  const getReelBackgroundColor = (likes) => {
    const likesNumber = convertToNumber(likes);
    if (likesNumber > followersCount * 0.5) {
      return "bg-green-200"; // Green for more than 50% of followers
    } else if (likesNumber > followersCount * 0.3) {
      return "bg-yellow-200"; // Yellow for more than 30% of followers
    } else if(likesNumber > followersCount * 0.1){
      return "bg-pink-100"
    }
    return ""; // No special background color
  };

  const handleBookmarkClick = async (reel) => {
    const isBookmarked = bookmarkedReels.some(
      (bookmarkedReel) => bookmarkedReel.link === reel.link
    );

    // Check if the reel is already bookmarked in Firestore
    const bookmarksRef = collection(db, "instagram");
    const bookmarkQuery = query(bookmarksRef, where("link", "==", reel.link));
    const querySnapshot = await getDocs(bookmarkQuery);

    if (isBookmarked) {
      // If it's already bookmarked, remove it from state and Firestore
      if (querySnapshot.docs.length > 0) {
        const docIdToDelete = querySnapshot.docs[0].id;
        await deleteDoc(doc(collection(db, "instagram"), docIdToDelete));
      }

      const updatedBookmarks = bookmarkedReels.filter(
        (bookmarkedReel) => bookmarkedReel.link !== reel.link
      );
      setBookmarkedReels(updatedBookmarks);
    } else {
      // If it's not already bookmarked, add it to Firestore with a custom document ID and update state
      try {
        // const customDocId = uuidv4(); // Set your custom document ID here
        if(caption.length>0){
          const docRef = await addDoc(collection(db, "instagram"), {
            link: reel.link,
            likes: reel.likes || 'N/A',
            comments: reel.comments || 'N/A',
            views: reel.views,
            likeRatio: reel.likeRatio,
            channel: reel.channel,
            posted: reel.posted,
            schedule: reel.schedule || null,
            caption: caption
          });
  
          const postRef = doc(db, "instagram", docRef.id);
          await updateDoc(postRef, {
            id: docRef.id,
          });
  
          setBookmarkedReels([...bookmarkedReels, {...reel, caption:caption}]);
        } else {
          toast.error("Please add caption to bookmark",{
            position: "top-right",
          });
        }
      } catch (e) {
        console.error("Error adding document: ", e);
      } finally {
        setCaption("");
        setSelectedPost(null);
        toast.success("Reel bookmarked successfully",{
          position: "top-right",
        })
      }
    }
  };
console.log(selectedPost,'hello selected post');
  const addCaption = async (reel) => {

    // Check if the reel is already bookmarked in Firestore
    const bookmarksRef = collection(db, "instagram");
    const bookmarkQuery = query(bookmarksRef, where("link", "==", reel.link));
    const querySnapshot = await getDocs(bookmarkQuery);

      // If it's already bookmarked, we can add caption to it
      if (querySnapshot.docs.length > 0) {
        const docIdToEdit = querySnapshot.docs[0].id;
        const postRef = doc(db, "instagram", docIdToEdit);
        let updatedData = {
          caption: caption,
        };
        await updateDoc(postRef, updatedData);
      }
      
      const updatedBookmarks = bookmarkedReels.map(
        (bookmarkedReel) => bookmarkedReel.link === reel.link? {...bookmarkedReel, caption: caption}: bookmarkedReel
        );
      setCaption("");
      setSelectedPost(null);
      setBookmarkedReels(updatedBookmarks);
  };

  const templates = {
    funky_coder: `Hit that ❤️ if you LOLed!\n.\n.\n.🔁 Share the laughs with your friends!\nGot a meme? Drop it in the comments! 💬💯.\n.\n.\n🤗 Follow: @funky_coder for more such content #funky_coder.`,
    "success.cool_": `🌟 Embrace success and positivity every day! 🌟\n.\nJoin us on this incredible journey of growth and achievement. 🚀❤️\nRemember to like, share, and comment to spread the inspiration! 📣💬\n.\n.\nLet's build a community that celebrates success together! 🌈🤝 #SuccessCool
    `,
  };

  function insertTemplate(templateName) {
    const textarea = document.getElementById("captionarea"); // Replace 'captionarea' with the actual ID of your textarea element
    if (templates.hasOwnProperty(selectedChannel)) {
      const template = templates[selectedChannel];
      const startPos = textarea.selectionStart; // Get the current cursor position
      const endPos = textarea.selectionEnd;

      // Insert the template at the cursor position or replace the selected text
      const currentValue = textarea.value;
      const newValue =
        currentValue.substring(0, startPos) +
        template +
        currentValue.substring(endPos, currentValue.length);

      // Update the textarea's value using setCaption
      setCaption(newValue.replace(templateName, ""));

      // Update the cursor position
      const newCursorPosition = startPos + template.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  }

  const postWithMaxSchedule = bookmarkedReels.filter((post)=>selectedChannel ? post.channel === selectedChannel : true).reduce((maxPost, post) => {
    if (!maxPost || post?.schedule > maxPost.schedule) {
      return post;
    }
    return maxPost;
  }, null);

  function formatInternationalTime(dateString, timeString, timeZone = 'Asia/Kolkata') {
    const dateTimeString = `${dateString} ${timeString}`;
    const dateTime = new Date(`${dateTimeString}`);
  
    return dateTime;
  }
// note: while saving we are using international timings and for displaying we are converting in ist.
  const getNewDate = (lastDate) => {
    if(!autoSchedule)return;
    return null; // stopping scheduling for now
    let newDate = new Date(lastDate);
  
    if(postsPerDay == 1){
      if(newDate.includes('14:30:00' )){ //checking whether previous time is 8 pm or not
        newDate.setTime(newDate.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours for the next day 8 PM
      }
    } else if(postsPerDay == 2){
      newDate.setTime(newDate.getTime() + 8 * 60 * 60 * 1000); // Add 8 hours
      if (newDate.getHours() === 0) {
        newDate.setTime(newDate.getTime() + 12 * 60 * 60 * 1000); // Add 12 hours for the next day
      }
    }








    newDate.setTime(newDate.getTime() + 4 * 60 * 60 * 1000); // Add 4 hours
    if (newDate.getHours() === 0) {
      newDate.setTime(newDate.getTime() + 12 * 60 * 60 * 1000); // Add 12 hours for the next day
    }
  
    // Compare with today's date
    const today = new Date();
    if (newDate < today) {
      newDate = today;
    }
  
    return newDate.toISOString();
  };
  
  return (
    <div className="flex justify-center items-center">
      <div className="absolute top-[15px] text-[30px] bg-[#00fff7] px-[15px] py-0 rounded-[10px] uppercase">
        {selectedChannel}
      </div>
      <div className="w-2/3 p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <input
            type="text"
            className="border rounded py-2 px-3 mr-2"
            placeholder="Followers Count"
            value={followersCount}
            onChange={handleFollowersCountChange}
          />
          
          <div className="flex items-center">
            {/* {autoSchedule && <select name="postsPerDay" id="postsPerDay"
              value={postsPerDay}
              onChange={(e) => {setPostsPerDay(e.target.value);localStorage.setItem('postsPerDay', e.target.value)}}
              className="border rounded py-2 px-3 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
            >
              <option value="1">1p/day</option>
              <option value="2">2p/day</option>
              <option value="3">3p/day</option>
            </select>}
            <input type="checkbox" name="autoSchedule" className="mr-2" id="autoSchedule" checked={autoSchedule} onChange={(e) => {setAutoSchedule(e.target.checked);localStorage.setItem('autoSchedule', e.target.checked)}} /> */}

            <select
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
              className="border rounded py-2 px-3 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
            >
              <option value="">Select Channel</option>
              {INSTAGRAM_ACCOUNTS.map((account) => (
                <option value={account.username} key={account.username}>
                  {account.name}
                </option>
              ))}
            </select>
            <div className="mr-5">
              {
                bookmarkedReels.filter(
                  (reel) => !reel.posted && reel.channel === selectedChannel
                )?.length
              }{" "}
              Remaining
            </div>
            <div className="flex items-center flex-col">
              <div className="flex">
            <div className="w-6 h-6 rounded-full bg-green-200 mr-2"></div>
            <span>&gt;50% Like Ratio</span>
              </div>
              <div className="flex">
            <div className="w-6 h-6 rounded-full bg-yellow-200 ml-4 mr-2"></div>
            <span>&gt;30% Like Ratio</span>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-2xl font-semibold mb-4">Data Analyzer</h1>
        <div className="flex">
        <input
          type="text"
          placeholder="Enter data to analyze (JSON format)"
          className="w-full border rounded py-2 px-3 mb-4"
          value={inputValue}
          onChange={handleInputChange}
        /> 
        <GoPaste
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  className="hide_on_mobile"
                  onClick={async () => {
                    const reels = await clipboard.readText();
                    setInputValue(reels);
                  }}
                />
        </div>
        <button
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          onClick={handleAnalyzeClick}
        >
          Analyze Reels Data
        </button>
        {data.length > 0 && (
          <div className="mt-4">
            <div className="flex mb-2">
              <button
                className={`flex-1 py-2 rounded-t-lg ${
                  activeTab === "mostLiked"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
                onClick={() => sortByLikes(data)}
              >
                Most Liked
              </button>
              <button
                className={`flex-1 py-2 rounded-t-lg ${
                  activeTab === "mostCommented"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
                onClick={sortByComments}
              >
                Most Commented
              </button>
              <button
                className={`flex-1 py-2 rounded-t-lg ${
                  activeTab === "mostViewed"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}
                onClick={sortByViews}
              >
                Most Viewed
              </button>
            </div>
            <h2 className="text-xl font-semibold mb-2">Analyzed Data</h2>
            {data.map((item, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg shadow-md mb-4 ${getReelBackgroundColor(
                  item.likes
                )}`}
              >
                <div className="flex justify-between">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline visited:text-purple-500"
                  >
                    Link: {item.link}
                  </a>
                  <div className="flex justify-center items-center">
                  <button
                    onClick={() => setSelectedPost(item)}
                    className={`p-2 rounded-full ${
                      bookmarkedReels.some(
                        (bookmarkedReel) => bookmarkedReel.link === item.link
                      )
                        ? "bg-yellow-500 text-white"
                        : "bg-gray-200 text-gray-600"
                    }`}
                  >
                    {bookmarkedReels.some(
                      (bookmarkedReel) => bookmarkedReel.link === item.link
                    )
                      ? "Bookmarked"
                      : "Bookmark"}
                  </button>
                  {bookmarkedReels.find(post => post.link === item.link) &&
                    (bookmarkedReels.find(post => post.link === item.link)?.caption ? (
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded mt-2 ml-5"
                        onClick={() => {
                          copyToClipboard(bookmarkedReels.find(post => post.link === item.link)?.caption);
                          toast.success(`Caption copied successfully!`, {
                            position: "top-right",
                          });
                        }}
                      >
                        <GoPaste />
                      </button>
                    ) : (
                      <button
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded mt-2 ml-5"
                        onClick={() => {
                          console.log(bookmarkedReels,'hello bookmarkedk reels');
                          setSelectedPost(bookmarkedReels.find(post => post.link === item.link));
                        }}
                      >
                        <IoMdAddCircleOutline />
                      </button>
                    ))}
                  </div>

                </div>
                <br />
                Likes: {item.likes}
                <br />
                Comments: {item.comments}
                <br />
                Views: {item.views}
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedPost && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-lg">
            <div className="flex justify-between">
              <div>
                Likes: {selectedPost.likes} &nbsp;
                Views: {selectedPost.views}
              </div>
              <div
                className="font-bold cursor-pointer text-red-500"
                onClick={() => setSelectedPost(null)}
              >
                X
              </div>
            </div>
            <div>
              Scheduled Till: {postWithMaxSchedule?.schedule ? changeTimeFormat(postWithMaxSchedule?.schedule): 'N/A'} <br />
              {getNewDate(postWithMaxSchedule?.schedule) && (`Scheduling This at: ${changeTimeFormat(getNewDate(postWithMaxSchedule?.schedule))}`)}
            </div>
            {(
              <div
                className="flex justify-center"
                style={{ alignItems: "center", marginBottom: 20 }}
              >
                <textarea
                  id="captionarea"
                  cols="30"
                  rows="10"
                  style={{ border: "1px solid", padding: 5, width: "100%" }}
                  value={caption}
                  onChange={async (e) => {
                    // const replacedText = await replaceUsernames(e.target.value);
                    if (e.target.value.includes("#temp")) {
                      insertTemplate(e.target.value);
                    } else {
                      setCaption(e.target.value);
                    }
                  }}
                  readOnly={false}
                />

                <GoPaste
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  className="hide_on_mobile"
                  onClick={async () => {
                    const caption = await clipboard.readText();
                    const replacedText = await replaceUsernames(caption, selectedChannel);
                    setCaption(replacedText);
                  }}
                />
              </div>
            )}
            <div className="flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-center w-full"
                onClick={()=>{handleBookmarkClick(postWithMaxSchedule?.schedule ? {...selectedPost, schedule:getNewDate(postWithMaxSchedule?.schedule)}: selectedPost)}}
              >
                Add BookMark
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyzeDataComponent;

import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getDocs,
  doc,
  where,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";
import { INSTAGRAM_ACCOUNTS } from "../constants";
import { GoPaste } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdArchive } from "react-icons/md";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import * as clipboard from "clipboard-polyfill";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { changeTimeFormat, copyToClipboard, replaceUsernames } from "../utils/common";
const PostInstagram = () => {
  const [selectedChannel, setSelectedChannel] = useState(
    localStorage.getItem("selectedChannel") || ""
  );
  const [showPosted, setShowPosted] = useState(false);
  const [showScheduled, setShowScheduled] = useState(false);
  const [posts, setPosts] = useState(
    localStorage.getItem("instagram_posts")
      ? JSON.parse(localStorage.getItem("instagram_posts"))
      : []
  );
  const [selectedPost, setSelectedPost] = useState(null);
  const [postedLink, setPostedLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [likes, setLikes] = useState("");
  const [views, setViews] = useState("");
  const [comments, setComments] = useState("");
  const [newPostUri, setNewPostUri] = useState("");
  const [shares, setShares] = useState("");
  const [modalField, setModalField] = useState("");
  const [caption, setCaption] = useState("");
  const [loading, setLoading] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState("");

  const openModal = (post) => {
    setSelectedPost(post);
    setIsModalOpen(true);
    setLikes("");
    setViews("");
    setComments("");
    setNewPostUri("");
    setShares("");
  };

  // Load bookmarkedReels from Firestore when the component mounts
  const [fetchOnDemand, setFetchOnDemand] = useState(false);

  useEffect(() => {
    const lastFetchTimestamp = localStorage.getItem("lastFetchTimestamp");
    const currentTime = Date.now();
    const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

    // Check if data should be fetched based on the last fetch timestamp
    if (
      !lastFetchTimestamp ||
      currentTime - lastFetchTimestamp >= oneHour ||
      fetchOnDemand
    ) {
      const fetchBookmarkedReels = async () => {
        try {
          // Create a query to get all documents from the Firestore collection
          setLoading(true);
          const q = query(collection(db, "instagram"));

          // Get the documents that match the query
          const querySnapshot = await getDocs(q);

          // Initialize an array to store the retrieved bookmarked reels
          const retrievedBookmarkedReels = [];

          // Loop through the documents and add them to the array
          querySnapshot.forEach((doc) => {
            retrievedBookmarkedReels.push(doc.data());
          });

          // Set the retrieved bookmarked reels to your state
          setPosts(retrievedBookmarkedReels);

          // Update the last fetch timestamp in local storage
          localStorage.setItem("lastFetchTimestamp", currentTime);
          console.log("data fetched");
          console.log(retrievedBookmarkedReels);
          // Update the posts in local storage
          localStorage.setItem(
            "instagram_posts",
            JSON.stringify(retrievedBookmarkedReels)
          );
        } catch (error) {
          console.error("Error fetching bookmarked reels: ", error);
        } finally {
          setLoading(false);
          setFetchOnDemand(false);
        }
      };

      fetchBookmarkedReels();
    } else {
      // If data should not be fetched, retrieve posts from local storage
      const storedPosts = localStorage.getItem("instagram_posts");
      if (storedPosts) {
        setPosts(JSON.parse(storedPosts));
        setLoading(false);
      }
    }
  }, [fetchOnDemand]);

  useEffect(() => {
    localStorage.setItem("selectedChannel", selectedChannel);
  }, [selectedChannel]);

  const markAsPosted = (post) => {
    setSelectedPost(post);
    setPostedLink(""); // Clear the input field
    setModalField("mark-posted");
  };
  const archivePost = (post) => {
    setSelectedPost(post);
    setPostedLink(""); // Clear the input field
    setModalField("archive-post");
  }
  const addCaption = (post) => {
    setSelectedPost(post);
    setPostedLink(""); // Clear the input field
    setModalField("add-caption");
  };

  const handlePosted = async () => {
    const isSchedule =
      (scheduleVisible !== "" &&
        scheduleVisible !== null &&
        selectedDateTime) ||
      modalField === "scheduled";
    if (selectedPost || isSchedule) {
      try {
        const postLink = selectedPost?.link || scheduleVisible;

        if (postLink) {
          // Check if the reel is already bookmarked in Firestore
          const bookmarksRef = collection(db, "instagram");
          const bookmarkQuery = query(
            bookmarksRef,
            where("link", "==", postLink)
          );
          const querySnapshot = await getDocs(bookmarkQuery);

          if (querySnapshot.docs.length > 0) {
            const docIdToEdit = querySnapshot.docs[0].id;

            const postRef = doc(db, "instagram", docIdToEdit);
            let updatedData = {};
            if (modalField === "mark-posted") {
              updatedData = {
                posted: new Date().toISOString(),
                postedUrl: postedLink,
              };
            }
            else if (modalField === "archive-post") {
              updatedData = {
                archived: new Date().toISOString(),
              };
            }
             else if (modalField === "add-caption") {
              updatedData = {
                caption: caption,
              };
            } else if (modalField === "add-schedule") {
              updatedData = {
                schedule: new Date(selectedDateTime).toISOString(),
              };
            } else if (modalField === "scheduled") {
              updatedData = {
                scheduled: true,
              };
            }
            await updateDoc(postRef, updatedData);

            // Update the state with the new data
            const updatedPosts = posts.map((post) => {
              return post.link === postLink
                ? {
                    ...post,
                    ...updatedData,
                  }
                : post;
            });

            setPosts(updatedPosts);
            localStorage.setItem(
              "instagram_posts",
              JSON.stringify(updatedPosts)
            );
            setSelectedPost(null); // Clear the selected post
            setCaption("");
            setPostedLink("");
            setModalField("");
            setScheduleVisible("");
            setSelectedDateTime(null);
            toast.success("Update successfully!", {
              position: "top-right",
            });
          }
        } else {
          console.error("Selected post does not have a valid 'id' property.");
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  const [scheduleStartFrom, setScheduleStartFrom] = useState("");
  const [isScheduling, setIsScheduling] = useState(false);
 
  const scheduleAll = async () => {
    if (!scheduleStartFrom) {
      toast.error("Please select a date and time!", {
        position: "top-right",
      });
      return;
    }
  
    const unscheduledPosts = posts.filter((post) => {
      return selectedChannel === post.channel && !post.schedule && !post.posted;
    });

    const startDate = new Date(scheduleStartFrom);
  
    setIsScheduling(true);
  
    try {
      for (const post of unscheduledPosts) {
        const bookmarksRef = collection(db, "instagram");
        const bookmarkQuery = query(bookmarksRef, where("link", "==", post.link));
        const querySnapshot = await getDocs(bookmarkQuery);
  
        if (querySnapshot.docs.length > 0) {
          const docIdToEdit = querySnapshot.docs[0].id;
  
          const postRef = doc(db, "instagram", docIdToEdit);
          let updatedData = {
            schedule: startDate.toISOString(),
          };
          await updateDoc(postRef, updatedData);
        }
  
        startDate.setTime(startDate.getTime() + 4 * 60 * 60 * 1000); // Add 4 hours
        if (startDate.getHours() === 0) {
          startDate.setTime(startDate.getTime() + 12 * 60 * 60 * 1000); // Add 12 hours for the next day
        }
      }
  
      setIsScheduling(false);
      setIsModalOpen(false);
      setScheduleStartFrom("");
      setFetchOnDemand(true);
  
      console.log(unscheduledPosts, 'hello bro');
      console.log(new Date(scheduleStartFrom).toISOString(), 'hello scheduleStartFrom');
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };
  
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  function handlePaste() {
    // Create a hidden input element
    const input = document.createElement("input");
    input.style.position = "absolute";
    input.style.left = "-1000px";
    input.style.top = "-1000px";

    // Add the input element to the document
    document.body.appendChild(input);

    // Focus on the input element
    input.focus();

    // Trigger a paste event and read the clipboard contents
    input.select();
    document.execCommand("paste");
    const pastedText = input.value;

    // Remove the input element
    document.body.removeChild(input);

    // Set the state with the pasted text
    setPostedLink(pastedText);
  }


  const addPost = async () => {
    if (!newPostUri) {
      toast.error("Please enter post url!", {
        position: "top-right",
      });
      return;
    }
    const docRef = await addDoc(collection(db, "instagram"), {
      link: newPostUri,
      likes: likes,
      comments: comments,
      views: views,
      shares: shares,
      likeRatio: null,
      channel: selectedChannel,
      posted: false,
    });
    const postRef = doc(db, "instagram", docRef.id);
    await updateDoc(postRef, {
      id: docRef.id,
    });
  };
  // Function to insert selected template at the cursor position in the textarea
  const templates = {
    funky_coder: `Hit that ❤️ if you LOLed!\n.\n.\n.🔁 Share the laughs with your friends!\nGot a meme? Drop it in the comments! 💬💯.\n.\n.\n🤗 Follow: @funky_coder for more such content #funky_coder.`,
    "success.cool_": `🌟 Embrace success and positivity every day! 🌟\n.\nJoin us on this incredible journey of growth and achievement. 🚀❤️\nRemember to like, share, and comment to spread the inspiration! 📣💬\n.\n.\nLet's build a community that celebrates success together! 🌈🤝 #SuccessCool
    `,
  };

  function insertTemplate(templateName) {
    const textarea = document.getElementById("captionarea"); // Replace 'captionarea' with the actual ID of your textarea element
    if (templates.hasOwnProperty(selectedChannel)) {
      const template = templates[selectedChannel];
      const startPos = textarea.selectionStart; // Get the current cursor position
      const endPos = textarea.selectionEnd;

      // Insert the template at the cursor position or replace the selected text
      const currentValue = textarea.value;
      const newValue =
        currentValue.substring(0, startPos) +
        template +
        currentValue.substring(endPos, currentValue.length);

      // Update the textarea's value using setCaption
      setCaption(newValue.replace(templateName, ""));

      // Update the cursor position
      const newCursorPosition = startPos + template.length;
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  }

  const [selectedDateTime, setSelectedDateTime] = useState(null);

  // This function will be called when a date and time is selected
  const handleDatetimeChange = (moment) => {
    setSelectedDateTime(moment);
    setModalField("add-schedule");
  };
  const postWithMaxSchedule = posts.filter((post)=>selectedChannel ? post.channel === selectedChannel : true).reduce((maxPost, post) => {
    if (!maxPost || post.schedule > maxPost.schedule) {
      return post;
    }
    return maxPost;
  }, null);
  
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="container mx-auto mt-6">
      <div className="flex justify-center mb-4 mr-4">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2 ml-5 text-center"
          onClick={() => openModal()} // Open the modal
        >
          Add New Post
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2 ml-5 text-center"
          onClick={() => setFetchOnDemand(true)} // Open the modal
        >
          Fetch
        </button>
      </div>
      <div className="mb-4 text-center">
        {/* Dropdown for selecting the channel */}
        <select
          className="border rounded px-2 py-1"
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
        >
          <option value="">Select Channel</option>
          {INSTAGRAM_ACCOUNTS.map((account) => (
            <option value={account.username} key={account.username}>
              {account.name}
            </option>
          ))}
          {/* Add more channel options here */}
        </select>
        <span>
          <input
            type="checkbox"
            name="checkbox"
            id="schedule"
            checked={showScheduled}
            onClick={() => setShowScheduled(!showScheduled)}
            style={{
              transform: "scale(1.3)",
              marginLeft: 15,
              cursor: "pointer",
            }}
          />{" "}
          <label htmlFor="schedule">Show Scheduled</label>
        </span>
      </div>

      <div className="mb-4 text-center">
        {/* Tabs for showing posted and non-posted items */}
        <button
          className={`px-4 py-2 rounded-tl-lg rounded-bl-lg ${
            !showPosted ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
          }`}
          onClick={() => setShowPosted(false)}
        >
          Non-Posted
        </button>

        <button
          className={`px-4 py-2 rounded-tr-lg rounded-br-lg ${
            showPosted ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
          }`}
          onClick={() => setShowPosted(true)}
        >
          Posted
        </button>
        <span className="ml-2">
          {posts
          .filter((post) =>
            selectedChannel ? post.channel === selectedChannel : true
          )
          .filter((post) => (showPosted ? post.posted : !post.posted))
          .filter((post) => (!post.archived))
          .length}
        </span>
      </div>

      <ul className="list-none p-0">
        {/* Display posts based on the selected channel and tab */}
        {posts
          .filter((post) =>
            selectedChannel ? post.channel === selectedChannel : true
          )
          .filter((post) => (showScheduled ? post.schedule : true))
          .filter((post) => (showPosted ? post.posted : !post.posted))
          .filter((post) => (!post.archived)) // remove archived posts
          .sort(
            (a, b) => (showPosted ? new Date(b.posted) - new Date(a.posted) : 0) // Sort only when showPosted is true
          )
          .sort((a, b) => {
            if (showPosted) {
              return 0;
            }
            // const aviews = convertToNumber(a.views);
            // const bviews = convertToNumber(b.views);
            // if (a.caption && b.caption) {
            //   return bviews - aviews; // Higher views with captions first
            // } else if (!a.caption && !b.caption) {
            //   return bviews - aviews; // Descending order of views for items without captions
            // } else if (a.caption && !b.caption) {
            //   return -1; // Higher view with caption comes before higher view without caption
            // } else {
            //   return 1; // Higher view without caption comes after higher view with caption
            // }
            return new Date(a.schedule) - new Date(b.schedule)
          })
          .map((post, index) => (
            <li key={index} className="border rounded-lg p-4 shadow-md mb-4">
              <p>Channel: {post.channel}</p>
              {post.likes && <p>Likes: {post.likes}</p>}
              {post.comments && <p>Comments: {post.comments}</p>}
              {post.views && <p>Views: {post.views}</p>}
              {!showPosted && (
                <p>
                  Schedule:
                  {!post.schedule ? (
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-4 rounded mt-1 ml-2"
                      onClick={() => setScheduleVisible(post.link)}
                    >
                      Schedule
                    </button>
                  ) : (
                    <span> {changeTimeFormat(post.schedule)}</span>
                  )}
                </p>
              )}
              {scheduleVisible === post.link && modalField !== "scheduled" && (
                <div className="flex">
                  <Datetime
                    inputProps={{
                      style: {
                        border: "1px solid",
                        width: "100%",
                        margin: 10,
                        marginLeft: 0,
                      },
                      className: "your-custom-class",
                      readOnly: true,
                    }}
                    onChange={handleDatetimeChange}
                  />
                </div>
              )}
              {scheduleVisible === post.link && selectedDateTime && (
                <div>
                  Selected Date and Time: {changeTimeFormat(selectedDateTime)}
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-4 rounded mt-1 ml-2"
                    onClick={() => {
                      handlePosted();
                      // setScheduleVisible("");
                      // setSelectedDateTime(null);
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
              {post.shares && <p>Shares: {post.shares}</p>}
              {post.posted && (
                <p>
                  Posted On: {new Date(post.posted).toLocaleDateString()}{" "}
                  {new Date(post.posted).toLocaleString(undefined, {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </p>
              )}
              {/* {post.schedule && !post.scheduled && !showPosted && (
                <span>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-4 rounded mt-1 ml-2"
                    onClick={() => {
                      setModalField("scheduled");
                      setScheduleVisible(post.link);
                      // Ask the user for confirmation
                      const userConfirmed = window.confirm(
                        "This will confirm that you have scheduled the post?"
                      );
                      if (userConfirmed) {
                        handlePosted();
                      }
                    }}
                  >
                    Scheduled
                  </button>
                </span>
              )} */}
              <p
                className={`mt-2 ${
                  post.posted ? "text-green-600" : "text-red-600"
                }`}
              >
                {post.posted ? "Posted" : "Not Posted"}
              </p>
              <a
                href={post.postedUrl || post.link}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-2 visited:bg-green-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>View Post</button>
              </a>
              {post.posted && (
                <a href={post.link}>
                  <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2 ml-5">
                    View Original
                  </button>
                </a>
              )}
              {!post.posted && (
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2 ml-5"
                  onClick={() => markAsPosted(post)}
                >
                  Mark Posted
                </button>
              )}
              {!post.posted &&
                (post.caption ? (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded mt-2 ml-5"
                    onClick={() => {
                      copyToClipboard(post.caption);
                      toast.success(`Caption copied successfully!`, {
                        position: "top-right",
                      });
                    }}
                  >
                    <GoPaste />
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-2 rounded mt-2 ml-5"
                    onClick={() => {
                      addCaption(post);
                    }}
                  >
                    <IoMdAddCircleOutline />
                  </button>
                ))}
                <MdArchive style={{cursor: 'pointer', display:'inline', height: '20px', width: '20px', marginLeft: '10px', marginBottom:10}} onClick={() => archivePost(post)}/>
            </li>
          ))}
        {posts
          .filter((post) =>
            selectedChannel ? post.channel === selectedChannel : true
          )
          .filter((post) => (showPosted ? post.posted : !post.posted))
          .length === 0 && (
          <li>
            <p className="text-center font-semibold">
              {showPosted ? "None" : "All"} of the posts for {selectedChannel}{" "}
              channel are posted
            </p>
          </li>
        )}
      </ul>

      {/* Modal for entering posted link */}
      {selectedPost && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-lg">
            <div className="flex justify-between">
              {modalField === "mark-posted" ? (
                <h2 className="text-xl font-semibold mb-4">
                  Enter Posted Link
                </h2>
              ) : modalField === "add-caption" ? (
                <h2 className="text-xl font-semibold mb-4">Enter Caption</h2>
              ) : (
                <h2 className="text-xl font-semibold mb-4">Archive Post</h2>
              )}
              <div
                className="font-bold cursor-pointer text-red-500"
                onClick={() => setSelectedPost(null)}
              >
                X
              </div>
            </div>
            {modalField === "mark-posted" && (
              <div
                className="flex justify-center"
                style={{ alignItems: "center" }}
              >
                <input
                  type="text"
                  style={{ width: "100%" }}
                  className="border rounded py-2 px-3 mb-2"
                  placeholder="Posted Link"
                  value={postedLink}
                  onChange={(e) => setPostedLink(e.target.value)}
                />
                <GoPaste
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  className="hide_on_mobile"
                  onClick={async () => {
                    const link = await clipboard.readText();
                    setPostedLink(link);
                  }}
                />
              </div>
            )}

            {modalField === "add-caption" && (
              <div
                className="flex justify-center"
                style={{ alignItems: "center", marginBottom: 20 }}
              >
                <textarea
                  id="captionarea"
                  cols="30"
                  rows="10"
                  style={{ border: "1px solid", padding: 5, width: "100%" }}
                  value={caption}
                  onChange={async (e) => {
                    // const replacedText = await replaceUsernames(e.target.value);
                    if (e.target.value.includes("#temp")) {
                      insertTemplate(e.target.value);
                    } else {
                      setCaption(e.target.value);
                    }
                  }}
                  readOnly={false}
                />

                <GoPaste
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  className="hide_on_mobile"
                  onClick={async () => {
                    const caption = await clipboard.readText();
                    const replacedText = await replaceUsernames(caption, selectedChannel);
                    setCaption(replacedText);
                  }}
                />
              </div>
            )}
            <div className="flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text-center w-full"
                onClick={handlePosted}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-lg m-8">
            <div className="flex justify-between">
              <h2 className="text-xl font-semibold mb-4">Enter Post Info</h2> <button className="absolute bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 mx-[150px]" style={{borderRadius:4}} onClick={scheduleAll}>Schedule All for {selectedChannel}</button>
              <h2
                className="text-xl font-semibold mb-4 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
              >
                X
              </h2>
            </div>
            {isScheduling && (
              <div>Scheduling...</div>
            )}
            Scheduled Till: {changeTimeFormat(postWithMaxSchedule?.schedule)} <br />
            Schedule From: {changeTimeFormat(scheduleStartFrom)}
            <Datetime
                    inputProps={{
                      style: {
                        border: "1px solid",
                        width: "100%",
                        margin: 10,
                        marginLeft: 0,
                      },
                      className: "your-custom-class",
                      readOnly: true,
                    }}
                    onChange={(moment)=>{
                      // setScheduleStartFrom(moment)
                      setScheduleStartFrom(moment)
                    }}
                  />
            <h4 className="text-lg font-semibold mb-4">
              Selected Channel:{" "}
              <span className="text-red-500">{selectedChannel}</span>
            </h4>
            <input
              type="text"
              className="border rounded py-2 px-3 mb-2 w-full"
              placeholder="Likes"
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
            />
            <input
              type="text"
              className="border rounded py-2 px-3 mb-2 w-full"
              placeholder="Views"
              value={views}
              onChange={(e) => setViews(e.target.value)}
            />
            <input
              type="text"
              className="border rounded py-2 px-3 mb-2 w-full"
              placeholder="Comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
            <input
              type="text"
              className="border rounded py-2 px-3 mb-2 w-full"
              placeholder="URL"
              value={newPostUri}
              onChange={(e) => setNewPostUri(e.target.value)}
            />
            <input
              type="text"
              className="border rounded py-2 px-3 mb-2 w-full"
              placeholder="Shares"
              value={shares}
              onChange={(e) => setNewPostUri(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={addPost}
              >
                Save Post
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostInstagram;

export const followScript = `
// Initialize a counter to keep track of clicked buttons
let clickedCount = 0;

// Define a function to click the button and display the count
function clickButtonWithCount() {
  // Select all buttons with the specified classes
  const buttonsToClick = document.querySelectorAll('button._acan._acap._acas._aj1-');

  if (buttonsToClick.length > clickedCount) {
    const buttonToClick = buttonsToClick[clickedCount];
    const buttonText = buttonToClick.querySelector('._aacl').textContent;

    // Check if the button text is "Follow" (to ignore "Following" buttons)
    if (buttonText === 'Follow') {
      buttonToClick.click();
      clickedCount++;
      console.log("Clicked: " + clickedCount);
    } else {
      // If it's a "Following" button, skip it and move to the next
      clickedCount++;
    }

    setTimeout(() => {
      clickButtonWithCount();
    }, 3000); // 3000 milliseconds (3 second) delay
  } else {
    console.log("Follow completed");

    // Check for the availability of the button every 1 seconds
    setTimeout(() => {
      const newButtonsToClick = document.querySelectorAll('button._acan._acap._acas._aj1-');
      if (newButtonsToClick.length > 0) {
        clickedCount = 0;
        clickButtonWithCount();
      }
    }, 1000); // 1000 milliseconds (1 seconds) delay
  }
}

// Start clicking the buttons with a 1-second delay
clickButtonWithCount();
`;

export const likeCommentsScript = `
// Select all spans with class _a9zu
const spans = document.querySelectorAll('span._a9zu');

// Define a function to click the nested div inside each span with a time gap
function clickNestedDivsWithDelay(spans, index) {
  if (index < spans.length) {
    const span = spans[index];
    const nestedDiv = span.querySelector('div > div');
    const svg = nestedDiv.querySelector('svg');
    const ariaLabel = svg.getAttribute('aria-label');
    if (nestedDiv && ariaLabel === 'Like') {
      nestedDiv.click();
      setTimeout(() => {
        clickNestedDivsWithDelay(spans, index + 1);
      }, 3000); // 3000 milliseconds (3 second) delay
    } else {
      // If there is no nested div, proceed to the next span
      setTimeout(() => {
        clickNestedDivsWithDelay(spans, index + 1);
      }, 0); // No delay
    }
  }
}

// Start clicking nested divs inside spans with a 1-second delay, starting from the first one
clickNestedDivsWithDelay(spans, 0);
`;

export const reelsExtractorScript = `
const reels = [];

function getReels() {
  const allReelRows = document.querySelectorAll("div._ac7v.x12nagc.xn8zkq8");

  allReelRows.forEach((row) => {
    const items = row.querySelectorAll('div.x1qjc9v5.x972fbf.xcfux6l.x1qhh985.xm0m39n.x9f619.x78zum5.xdt5ytf.x2lah0s.xln7xf2.xk390pu.xdj266r.xat24cr.x1mh8g0r.xexx8yu.x4uap5.x18d9i69.xkhd6sd.x1n2onr6.x11njtxf.xpzaatj.xw3qccf');

    items.forEach((item) => {
      const reelElement = item.querySelector("._a6hd");
      const link = "https://www.instagram.com/" + reelElement.getAttribute("href");

      // Check if the link is already in the reels array
      const existingReel = reels.find((reel) => reel.link === link);

      if (!existingReel) {
        const ulElement = reelElement?.querySelector("._abpo");
        const liElements = ulElement?.querySelectorAll("li");
        let likes = liElements?.[0]?.textContent;
        let comments = liElements?.[1]?.textContent;
        const element = reelElement?.querySelector("._aajy");
        const spanWithClass = element?.querySelector(".html-span");
        // Get the views.
        const views = spanWithClass?.textContent;

        // Add a null check for link to avoid adding reels with undefined links
        if (link) {
          reels.push({
            link,
            likes,
            comments,
            views,
          });
        }
      }
    });
  });

  // Console log the reels array every time the length changes
  if (reels.length !== previousLength) {
    console.log(reels);
    previousLength = reels.length;
  }

  // Set a timeout to call the function again after 1 second
  setTimeout(getReels, 1000);
}

// Initialize the previous length variable
let previousLength = 0;

getReels();  
`;
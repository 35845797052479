
export const convertToNumber = (value) => {
    if (typeof value === "string") {
      value = value.replace(/,/g, "");
      if (value.includes("K")) {
        value = parseFloat(value) * 1000;
      } else if (value.includes("M")) {
        value = parseFloat(value) * 1000000;
      }
      return parseFloat(value);
    }
    return value;
  };


  export function copyToClipboard(text) {
    // Create a textarea element
    const textarea = document.createElement("textarea");

    // Set the value of the textarea to the text you want to copy
    textarea.value = text;

    // Make the textarea non-editable to prevent focus and selection
    textarea.setAttribute("readonly", "");

    // Hide the textarea from view
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";

    // Append the textarea to the document body
    document.body.appendChild(textarea);

    // Select and copy the text from the textarea
    textarea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);
  }

  export  function replaceUsernames(text, channel) {
    // Define a regular expression to match usernames starting with @
    const regex = /@[\w._]+/g;

    // Replace all matched usernames with @abc
    const replacedText = text.replace(regex, `@${channel}`);

    return replacedText.startsWith('\n') ? replacedText.replace(/^\s*\n/, '') : replacedText;;
  }
  

export const changeTimeFormat = (date) => {
    const newDate =
      new Date(date).toLocaleDateString() +
      " " +
      new Date(date).toLocaleString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    return newDate;
  };
import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="container mx-auto mt-10 flex justify-center">
      {/* First Card: Redirect to Instagram */}
      <div className="max-w-sm mx-4 bg-pink-500 rounded-lg overflow-hidden shadow-lg transition transform hover:scale-105 cursor-pointer">
        <div className="p-4">
          <h2 className="text-2xl text-white font-semibold mb-2">
            Go to Instagram
          </h2>
          <p className="text-white">Click here to redirect to Instagram</p>
        </div>
        <div className="bg-pink-700 text-white py-2 text-center font-semibold hover:bg-pink-600">
          <Link to={"/instagram"}>Go to Instagram</Link>
        </div>
      </div>

      {/* Second Card: Redirect to Telegram */}
      <div className="max-w-sm mx-4 bg-blue-500 rounded-lg overflow-hidden shadow-lg transition transform hover:scale-105 cursor-pointer">
        <div className="p-4">
          <h2 className="text-2xl text-white font-semibold mb-2">
            Go to Telegram Uploader
          </h2>
          <p className="text-white">Click here to redirect to Telegram</p>
        </div>
        <div className="bg-blue-700 text-white py-2 text-center font-semibold hover:bg-blue-600">
          <a
            href="https://main--incredible-mooncake-f92479.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Telegram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
